/**
 * @module XVP
 */
import config from 'config';
import errorCategories from 'constants/error-categories';
import featuresTypes from 'constants/xvp-ads-types';
import { dispatches } from 'lib/event-dispatcher';
import Logger from 'lib/logger';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import XVPDEVPLAT from './xvp-devplat';
import { XVP_API_CONFIGS, XVP_API_URLS } from 'constants/xvp';
import SplunkLogger from 'lib/telemetry/splunk-logger';
import { lookupError } from 'lib/helpers';

window.onbeforeunload = (e) => {
  window.isWindowUnloaded = true;
};

/**
 * Singleton module for working with XVP endpoints. This object should be fed a
 * valid session (xsct, sat, deviceId, xboAccountId) before interaction with XVP.
 */

const CLIENT_ID = 'stream-chromecast';
const logger = new Logger('XVP', { background: 'blue', color: 'white' });
@dispatches('xvp')

class XVP {
  _session = {
    partnerId: '',
    deviceId: '',
    clientId: '',
    accountId: '',
    xboAccountId: '',
    xsct: ''
  };
  params = {
    accountId: '',
    deviceId: '',
    partnerId: ''
  };
  headers = {
    'Accept': 'application/json',
    'Authorization': '',
    'Content-Type': 'application/json'
  };
  rightsRequestBody = {}


  /**
  * Note: partnerId, clientId are also sent in the _session
  */
  constructor() {}

  get session() {
    return this._session;
  }

  set session(tokenSummary) {
    const token = Object.assign({}, tokenSummary, tokenSummary.tokenSummary) || tokenSummary || {};
    if (!token.xsct || !token.serviceAccessToken || !token.deviceId || !token.xboAccountId) {
      logger.log('set session failed, missing necessary session data');
      return;
    }
    this._session = token;
    this.rightsRequestBody = { xsct: token.xsct };
    this._setApiParams(token);

    return this._session;
  }

  getFeatures() {
    return this._session && this._session.featuresResult && this._session.featuresResult.features || {};
  }

  getFeature(xvpFeature) {
    return Boolean(this.getFeatures()[xvpFeature] === 'true') || false;
  }

  _setApiParams(token) {
    this.params = {
      accountId: token.xboAccountId,
      deviceId: token.deviceId,
      partnerId: token.partnerId
    };

    this.headers = {
      ...this.headers,
      Authorization: token.serviceAccessToken
    };
  }


  /**
   * Send XVP request using endpoint defined in supportedResources
   * @param {object} args - request params
   * @return {Promise}
   */
  async send(args) {
    const endPoint = args.endPoint;
    const xvpApiConfig = XVP_API_CONFIGS[endPoint];
    window.isWindowUnloaded = false;

    if (!xvpApiConfig) {
      return XVPDEVPLAT.send(this.session, args);
    }

    args = {
      ...args,
      pathParams: {
        ...args.pathParams,
        ...this.params
      }
    };

    const { pathParams, queryParams, body = null } = args;
    const request = this._createRequest(endPoint, pathParams, queryParams);
    request.options.headers = this.headers;
    request.options.pathParams = pathParams;

    if (body) {
      request.options.body = JSON.stringify(body);
    }

    if (xvpApiConfig.serviceName === 'RIGHTS') {
      request.options.body = JSON.stringify(this.rightsRequestBody);
    }

    const sendDate = (new Date()).getTime();
    let responseTimeMs = 0;

    try {
      const response = await fetch(request.url, request.options);

      const receiveDate = (new Date()).getTime();
      responseTimeMs = receiveDate - sendDate;
      let data = {};

      if (response.status !== 204) {
        data = await response.json();
      }

      const splunkObj = {
        headers: {
          traceId: response.headers.get('trace-id'),
          traceResponse: response.headers.get('traceresponse')
        },
        xhr: {
          ok: response.ok,
          status: response.status,
          subCode: data.statusSubCode || '',
          statusText: response.statusText,
          type: response.type,
          url: response.url
        },
        request: {
          params: args
        },
        responseTime: responseTimeMs
      };

      if (response.status >= 400 || !response.ok) {
        throw ({
          errorMessage: data.title,
          errorReason: data.failureMessage,
          ...splunkObj
        });
      }

      SplunkLogger.onSuccess(splunkObj);

      return data;
    } catch (error) {
      /**
       * Even a success call can come into the catch()
       * if the window is unloaded before the response comes back to the browser.
      */
      if (window.isWindowUnloaded) {
        return;
      }

      /**
       * Browser errors can be strings such as:
       * 'TypeError: Failed to fetch'
       * 'TypeError: Browser refused to fetch'
       * etc
      */
      let clientBrowserError = {};
      if (!error.errorMessage) {
        clientBrowserError = {
          errorMessage: `${error}`
        };
      }

      const xhr = error.xhr || {};
      const errorDetails = lookupError(['api', endPoint, xhr.status, xhr.subCode]);

      SplunkLogger.onError({
        ...args,
        ...error,
        ...clientBrowserError,
        lookup: errorDetails,
        url: request.url
      });

      throw (error);
    }
  }

  _createRequest(endPoint, pathParams, queryParams) {
    const apiProps = XVP_API_CONFIGS[endPoint];
    let path = apiProps['path'];
    const serviceName = apiProps['serviceName'];
    const apiEnvironment = config.environment.API_ENV || 'prod';
    const domainName = XVP_API_URLS[serviceName][apiEnvironment] || XVP_API_URLS[serviceName].prod;

    Object.keys(pathParams).forEach((key) => {
      path = path.replace(`{${key}}`, pathParams[key]);
    });

    path += `?clientId=${CLIENT_ID}`;
    queryParams &&
      Object.keys(queryParams).forEach((key) => {
        path += `&${key}=${queryParams[key]}`;
      });

    return {
      url: domainName + path,
      options: {
        method: apiProps['method'] || 'GET'
      }
    };
  }

  /**
   * Prepares channel error to be throw to app
   *  App.error({ category, error, code, subCode, description }) {
   * @param {string} errorType - Channel error to throw
   * @param {object} error
   * @param {object} options - optional data to be passed with errors
   * @return {object} - error obj formatted to be thrown for the app
   */
  errorFormatted({ errorType, error, options }) {
    senderDebugger.debugErrorMessage(`errorFormatted: ${ errorType }`, {
      channelId: options && options.channelId,
      xvpTVGrid: this.getFeature(featuresTypes.xvpTVGrid),
      errorDetail: error
    });
    const channelId = options ? 'ChannelId: ' + options.channelId : '';
    const listingId = options && options.listingId ? ' - ListingId: ' + options.listingId : '';// 'ListingId: unknown';
    const stationId = options && options.stationId ? ' - StationId: ' + options.stationId : '';// 'stationId: unknown';
    const errorObj = error && error.response ? {
      ok: error.response && error.response.ok,
      redirected: error.response && error.response.redirected,
      status: error.response && error.response.status,
      statusText: error.response && error.response.statusText,
      type: error.response && error.response.type,
      url: error.response && error.response.url,
      detail: error.detail || 'no details',
      stack: error.stack+ ' < /br>',
      message: error.message
    } : Object.assign({}, error, options);
    const isXVP = this.getFeature(featuresTypes.xvpTVGrid);
    const errorMap = {
      'getChannelMap': {
        category: errorCategories.api,
        code: 'getChannelMap',
        subCode: '',
        description: `Cannot get getEntitledTvGridChannels for ${ options ? options.channelId : 'unknown' } - channel map call failed`,
        endpoint: options && options.logResponse ? options.logResponse.url : 'getEntitledTvGridChannels',
        serviceName: 'getEntitledTvGridChannels',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'getChannelMap',
          description: `Cannot get getEntitledTvGridChannels for ${ channelId } - channel map call failed`,
          fatal: true
        },
        isXVP: isXVP,
        errorDetail: error,
        errorResponse: errorObj
      },
      'channelMapMissingChannelId': {
        category: errorCategories.api,
        code: 'channelMapMissingChannelId',
        description: `Cannot find channelId ${ options ? options.channelId : 'unknown' } in channel map`,
        endpoint: 'internal',
        serviceName: 'channelIdNotFoundinChannelMap',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'channelIdNotFoundinChannelMap',
          description: `Cannot get ${channelId}${listingId}${stationId} from within channel map`,
          fatal: true
        },
        isXVP: this.getFeature(featuresTypes.xvpTVGrid),
        errorDetail: error,
        errorResponse: errorObj
      },
      'getTvGridChunks': {
        category: errorCategories.api,
        code: 'getTvGridChunk',
        description: `Cannot get getTvGridChunks 
        ${channelId}${listingId}${stationId}- tv chunks call failed`,
        serviceName: 'getTvGridChunk',
        error: {
          category: errorCategories.api,
          code: 'api',
          subCode: 'getTvGridChunk',
          description: `Cannot get getTvGridChunks ${channelId}${listingId}${stationId} - tv chunks call failed.`,
          fatal: true
        },
        isXVP: isXVP,
        errorDetail: error,
        errorResponse: errorObj
      }
    };
    senderDebugger.debugErrorMessage(`errorFormatted: ${ errorType }`, {
      channelId: options && options.channelId,
      xvpTVGrid: this.getFeature(featuresTypes.xvpTVGrid),
      errorObjCreated: errorMap[errorType],
      errorDetail: error
    });
    return errorMap[errorType];
  }
}

export default new XVP();
export { XVP };
