import { dispatches } from 'lib/event-dispatcher';
import api from 'api';
import heartbeatEvents from 'constants/heartbeat-events';
import ResumePoints from 'resume-points';
import featuresTypes from 'constants/xvp-ads-types';
import XVP from 'lib/xvp';
import Logger from 'lib/logger';
import { senderDebugger } from './lib/debug/sender-receiver-debug';
import { getProperty } from 'lib/helpers';
import splunkLogger from './lib/telemetry/splunk-logger';

const logger = new Logger('Heartbeat', { background: 'deepskyblue', color: 'white' });

const heartbeatInterval = 120e3; // 2 minutes
const xtvApiEndpoints = {
  linear: {
    beat: 'linearStreamHeartbeat',
    stop: 'stopWatchingLinearStream'
  },
  purchase: {
    beat: 'purchasedVodStreamHeartbeat',
    stop: 'stopWatchingPurchasedVod',
    finish: 'finishWatchingPurchasedVod'
  },
  recording: {
    beat: 'recordingHeartbeat',
    stop: 'stopWatchingRecording',
    finish: 'finishWatchingRecording'
  },
  tveLinear: {
    beat: 'tveLinearStreamHeartbeat',
    stop: 'stopWatchingLinearStreamTve'
  },
  tveRecording: {
    beat: 'tveRecordingHeartbeat',
    finish: 'finishWatchingRecording',
    stop: 'stopWatchingRecording'
  },
  tveVod: {
    beat: 'tveVodStreamHeartbeat',
    stop: 'stopWatchingTveVod',
    finish: 'finishWatchingTveVod'
  },
  vod: {
    beat: 'vodStreamHeartbeat',
    stop: 'stopWatchingVod',
    finish: 'finishWatchingVod'
  }
};

const xvpEndpoints = {
  linear: {
    beat: 'putHeartbeatLinear',
    stop: 'deleteHeartbeatLinear'
  },
  tveLinear: {
    beat: 'putHeartbeatTVELinear',
    stop: 'deleteHeartbeatTVELinear'
  },
  vod: {
    beat: 'putHeartbeatVod',
    stop: 'deleteHeartbeatVod'
  },
  tveVod: {
    beat: 'putHeartbeatTveVod',
    stop: 'deleteHeartbeatTveVod'
  },
  purchase: {
    beat: 'putHeartbeatPurchase',
    stop: 'deleteHeartbeatPurchase'
  },
  recording: {
    beat: 'putHeartbeatRecording',
    stop: 'deleteHeartbeatRecording'
  },
  tveRecording: {
    beat: 'putHeartbeatTveRecording'
  }
};

/**
 * Makes XTV API heartbeat requests
 */
@dispatches('heartbeat')
class Heartbeat {
  _hearbeatInterval = null;

  _watchable = null;

  _getEndpoint(type) {
    const isXvpHeartbeats = XVP.getFeature(featuresTypes.xvpHeartbeats);
    const isXvpGrid = XVP.getFeature(featuresTypes.xvpTVGrid);
    const endpointMap = isXvpHeartbeats ? xvpEndpoints : xtvApiEndpoints;

    if (this._watchable.isLinear()) {
      return isXvpGrid ?
        (this._watchable.hasTveContextType() ? endpointMap.tveLinear[type] : endpointMap.linear[type]) :
        (this._watchable.isLinearTve() ? endpointMap.tveLinear[type] : endpointMap.linear[type]);
    }
    // todo: when XVP watch options are implemented, use productContexts as with linear
    if (this._watchable.isVod()) {
      return endpointMap.vod[type];
    }

    if (this._watchable.isTve()) {
      return endpointMap.tveVod[type];
    }

    if (this._watchable.isTveRecording()) {
      return endpointMap.tveRecording[type];
    }

    if (this._watchable.isRecording()) {
      return endpointMap.recording[type];
    }

    if (this._watchable.isPurchase()) {
      return endpointMap.purchase[type];
    }
  }

  _getParams() {
    if (this._watchable.isLinear()) {
      return { streamId: this._watchable.getLinearProp('streamId') };
    }

    if (this._watchable.isPurchase() || this._watchable.isVod() || this._watchable.isTve()) {
      return { mediaId: this._watchable.mediaId };
    }

    if (this._watchable.isRecording()) {
      return { recordingId: this._watchable.id };
    }
  }

  _getXvpParams() {
    const watchable = this._watchable;
    const mediaAccountName = watchable.accountName || watchable.mediaAccountName;
    let params = {};

    if (watchable.isLinear()) {
      params = {
        pathParams: {
          streamId: (watchable.channel || {}).streamId || watchable.streamId || watchable.listingId || ''
        }
      };
    }
    if (watchable.isAnyVod()) {
      params = {
        pathParams: {
          mediaGuid: watchable.mediaGuid || ''
        }
      };
    }
    if (watchable.isRecording()) {
      params = {
        pathParams: {
          recordingId: watchable.id || ''
        }
      };
    }
    if (watchable.isRental()) {
      params.queryParams = {
        includePurchases: true
      };
    }

    if (mediaAccountName) {
      params.queryParams = {
        ...params.queryParams,
        mediaAccountName
      };
    }

    return params;
  }

  _getAssetUrn() {
    if (this._watchable.isLinear()) {
      return 'merlin:linear:stream:' + this._watchable.getLinearProp('streamId');
    }

    if (this._watchable.isPurchase() || this._watchable.isVod() || this._watchable.isTve()) {
      return 'merlin:media:' + this._watchable.accountName + ':' + this._watchable.mediaGuid;
    }

    if (this._watchable.isRecording()) {
      return 'xrn:saved:recording:' + this._watchable.id;
    }
  }

  _sendHeartbeat = async () => {
    if (XVP.getFeature(featuresTypes.xvpHeartbeats)) {
      try {
        const xvpParams = this._getXvpParams();
        await XVP.send({
          endPoint: this._getEndpoint('beat'),
          ...xvpParams
        });
      } catch (error) {
        if (error) {
          const xvpError = error.xhr || {};
          const { status: code, subCode } = xvpError;

          logger.error(error);

          // 403 responses (except 403-102) are fatal
          if (code === 403 && subCode !== '403-102') {
            this.dispatchEvent(heartbeatEvents.failed, { error: {
              endpoint: getProperty(error, 'request.params.endPoint'),
              code,
              subCode,
              ...error
            } });
          }
        }
      }
    } else {
      try {
        await api.send({
          endpoint: this._getEndpoint('beat'),
          params: this._getParams()
        });
      } catch (error) {
        const xtvError = (error.xhr && error.xhr.xtv) || {};
        const { code, subCode } = xtvError;
        // 403 responses (except 403-102) are fatal
        if (code === '403' && subCode !== '102') {
          this.dispatchEvent(heartbeatEvents.failed, { error: xtvError });
        } else {
          splunkLogger.onError(xtvError);
        }
      }
    }
    senderDebugger.debugHeartbeatMessage('Heart Beat Event ', {
      xvpHeartBeats: XVP.getFeature(featuresTypes.xvpHeartbeats),
      endPoint: this._getEndpoint('beat'),
      params: this._getParams()
    });
  }

  /**
   * Start making heartbeat requests
   *
   * Sets an interval timer to make XTV API requests for heartbeat
   *
   * @param {object} watchable - Current watchable
   */
  start(watchable) {
    this.stop();

    this._watchable = watchable;
    this._heartbeatTimer = setInterval(this._sendHeartbeat, heartbeatInterval);
  }

  /**
   * Stop making heartbeat requests
   */
  stop() {
    this._heartbeatTimer = clearInterval(this._heartbeatTimer);
  }

  /**
   * Sends a stop watching request
   */
  async stopWatching() {
    if (!this._watchable) {
      return;
    }
    if (XVP.getFeature(featuresTypes.xvpHeartbeats)) {
      const xvpParams = this._getXvpParams();

      await XVP.send({
        endPoint: this._getEndpoint('stop'),
        ...xvpParams
      });
    } else {
      try {
        await api.send({
          endpoint: this._getEndpoint('stop'),
          params: this._getParams()
        });
      } catch (error) {
        const xtvError = (error.xhr && error.xhr.xtv) || {};
        splunkLogger.onError(xtvError);
      }
    }
  }

  /**
   * Send a finished watching request
   */
  async finishWatching() {
    if (!this._watchable) {
      return;
    }

    if (XVP.getFeature(featuresTypes.xvpHeartbeats)) {
      const watchable = this._watchable;
      await XVP.send({
        endPoint: 'deleteResumePoint',
        pathParams: {
          entityType: (watchable.isTve() || watchable.hasTveContextType()) ? 'tve' :
            (watchable.isRecording() ? 'dvr' : 'Program'),
          entityId: (watchable.isTve() || watchable.hasTveContextType()) ?
            watchable.mediaGuid :
            (watchable.isRecording() ?
              watchable.id :
              (watchable.programId || (watchable.creativeWork || {}).programId)
            )
        }
      });
    } else {
      const endpoint = this._getEndpoint('finish');

      if (!endpoint) {
        return;
      }

      await api.send({
        endpoint,
        params: this._getParams()
      });
    }

    // Re-cache resume points
    setTimeout(() => ResumePoints.getAllResumePoints(), 2500);
  }
}

export default new Heartbeat();
